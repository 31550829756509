<div class="features-area ptb-100 pb-0">
  <div class="container">
    <div class="section-title">
      <span class="text">Awesome Rewards</span>
      <h2>Sweet Perks Await</h2>
      <p class="description">
        When you order directly through our website, you'll automatically be
        enrolled in our program and eligible for exclusive discounts at
        checkout. No more need to jump through any hoops or remember any special
        codes.
      </p>
    </div>
    <div class="row align-items-center">
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-sale-discount"></i>
          </div>
          <div class="features-content">
            <h3>Amazing Offers and Discounts</h3>
            <p>
              Join Our Loyalty Program Today and get exclusive access to limited
              offers
            </p>
          </div>
        </div>
        <!-- <div class="single-features">
          <div class="icon">
            <i class="icofont-box"></i>
          </div>
          <div class="features-content">
            <h3>Birthday Rewards</h3>
            <p>Celebrate your special day with exclusive discounts</p>
          </div>
        </div> -->
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="features-img">
          <img src="assets/img/loyalty/loyalty-program.svg" alt="loyalty-img" />
        </div>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        <div class="single-features">
          <div class="icon">
            <i class="icofont-food-basket"></i>
          </div>
          <div class="features-content">
            <h3>Get 10% off every 10th order</h3>
            <p>
              For Supporting local business by ordering direct, you deserve
              something special
            </p>
          </div>
        </div>
        <!-- <div class="single-features">
          <div class="icon">
            <i class="icofont-cocktail"></i>
          </div>
          <div class="features-content">
            <h3>Exclusive Access to limited offers</h3>
            <p>
              Get Early access to limited-time offers and exclusive promotions
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
