import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  readonly testimonials = [
    {
      message: `Easily the best pizza I've had in a while, can't wait to go back! If you're into the old school beach industrial aesthetic, this is where you wanna go.`,
      userName: 'Bedlam Wilhelm Rigney (Loony Living)',
    },
    {
      message: `10 out of 10. Jumbo pizzas are the best. Very nice food and good value for money`,
      userName: 'Josh Cosgrove',
    },
    {
      message:
        'Great pizzas from here,  plenty of topping, not too greasy and always as ordered.',
      userName: 'Luke Tugwell',
    },
    {
      message: `Had pizza for dinner tonight and got it delivered.  The driver was very polite and the pizza and garlic bread was lovely.  Best in the area. Well done Eva's Pizzeria. 👏`,
      userName: 'Brian Johnstone',
    },
    {
      message: `Frequent orderer,
        I love the loyalty rewards scheme through their own app, This is great as uber eats charges so much. Always perfect pizzas with such a great menu selection & the chips are unbelievable. Good delivery times.`,
      userName: 'Adelaide Undercover',
    },
  ];

  feedbackSlides: OwlOptions = {
    items: 1,
    margin: 0,
    nav: false,
    loop: true,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
  };
}
