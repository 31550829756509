import { baseConfig } from './base-config';
import { IConfig } from './config.interface';

/**
 * Client specific overrides
 */
export const config: IConfig = {
  ...baseConfig,
  //! All client specific override needs to be applied after here.

  /**
   * Enable this to use default shared api hosted at api.storefront.readyorder.com.au
   */
  apiSubDomainPrefix: null,

  appSubDomainPrefix: 'www',

  appDevSubDomainPrefix: 'storefront',

  repository: 'ReadyOrder_EvasPizzeria',

  appName: 'EvasPizzeria',

  domainName: 'evaspizzeria.com',

  mapRootDomain: true,

  storefront: {
    contact: {
      email: [] as { label: string; value: string }[],
      phone: [{ label: '(08) 8242 4545', value: '0882424545' }],
      address: '1/195 Victoria Rd, Largs Bay SA 5016',
      googleLink: 'https://goo.gl/maps/2WJLUJnppq1gH9JM7',
    },
    openingHours: {
      week: { startTime: '4:00PM', endTime: '10:00PM' },
      weekend: { startTime: '4:00PM', endTime: '11:00PM' },
    },
    restaurantId: 'eoJ9Psbl6CjrkaVG1W',
    gloriaFoodCuid: 'a546606a-2456-44ed-a2e4-ee46fe71c364',
    gloriaFoodRuid: '41b214d2-9c04-4086-bb5e-fbf95200e976',
    gloriaFoodUrl: 'https://pos.globalfoodsoft.com',
  },

  awsMailHostingProvider: {
    ...baseConfig.awsMailHostingProvider,
    emailSubject: 'New Website enquiry',
    emailToAddress: 'help@evaspizzeria.com.au',
  },
};
