<div id="about" class="story-area ptb-100">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="story-image">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img1.jpg" alt="image" />
              <img src="assets/img/about/about-img2.jpg" alt="image" />
            </div>
            <div class="col-lg-6 col-md-6">
              <img src="assets/img/about/about-img3.jpg" alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="story-content">
          <div class="section-title">
            <span class="text">About us</span>
            <h2>Welcome To Eva's Pizzeria</h2>
          </div>
          <p>
            Eva's Pizzeria is known for serving the best pizza pies in South
            Australia. Whether you are in the mood to indulge in something new
            or want to enjoy some old favorites, we promise that our inventive
            menu and attentive service will leave you truly satisfied.
          </p>
          <p>
            No matter what your occasion calls for or your appetite demands, the
            friendly staff promise to make your next dining experience a
            pleasant one.
          </p>
          <br />
          <p>
            At Eva's Pizzeria, you’ll find various selection from wings, tossed
            salads, specialty pizzas, pastas and more! Come in with your family,
            friends or even for date night! We have something that everyone will
            enjoy. We offer our full menu for both dine in and take-away.
          </p>
          <div class="quote-info">
            <span> - Eva's Pizzeria Team</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="line-bg"><img src="assets/img/line.png" alt="line" /></div>
</div>
