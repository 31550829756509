import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StickyNavModule } from 'ng2-sticky-nav';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { TabsModule } from 'ngx-tabset';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/common/about/about.component';
import { CallToActionComponent } from './components/common/call-to-action/call-to-action.component';
import { ChefsComponent } from './components/common/chefs/chefs.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { GalleryComponent } from './components/common/gallery/gallery.component';
import { InstagramComponent } from './components/common/instagram/instagram.component';
import { loyaltyComponent } from './components/common/loyalty/loyalty.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { OfferComponent } from './components/common/offer/offer.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { RestaurantMenuComponent } from './components/common/restaurant-menu/restaurant-menu.component';
import { StunningThingsComponent } from './components/common/stunning-things/stunning-things.component';
import { VideoComponent } from './components/common/video/video.component';
import { OrderNowButtonComponent } from './components/custom/order-now-button/order-now-button.component';
import { TableReservationButtonComponent } from './components/custom/table-reservation-button/table-reservation-button.component';
import { HomeBannerComponent } from './components/pages/home/home-banner/home-banner.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    InstagramComponent,
    ContactComponent,
    PartnerComponent,
    FeedbackComponent,
    FeaturesComponent,
    GalleryComponent,
    CallToActionComponent,
    ChefsComponent,
    RestaurantMenuComponent,
    VideoComponent,
    StunningThingsComponent,
    AboutComponent,
    OfferComponent,
    NotFoundComponent,
    HomeBannerComponent,
    OrderNowButtonComponent,
    TableReservationButtonComponent,
    loyaltyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'storefront' }),
    BrowserTransferStateModule,
    /**
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     *
     *                     N O T   I N   U S E
     *  We have disabled the server state transfer login to
     * allow the site to property fetch latest menu items from API
     *
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     *
     */
    // TransferHttpCacheModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    ReactiveFormsModule,
  ],
  providers: [
    /**
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     *
     *                     N O T   I N   U S E
     *  We have disabled the server state transfer login to
     * allow the site to property fetch latest menu items from API
     *
     * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
     *
     */
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ClientStateInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
