import { environment } from '../../../../environments/environment';
import { Component } from '@angular/core';

@Component({
  selector: 'app-order-now-button',
  templateUrl: './order-now-button.component.html',
  styleUrls: ['./order-now-button.component.scss'],
})
export class OrderNowButtonComponent {
  gloriaFoodCuid = environment.gloriaFoodCuid;
  gloriaFoodRuid = environment.gloriaFoodRuid;
}
