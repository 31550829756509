<div id="contact" class="contact-area ptb-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6">
        <div class="contact-box">
          <h3>Contact Us</h3>
          <p>
            <a href="{{ config.contact.googleLink }}" target="_blank"
              ><i class="icofont-google-map"></i>
              {{ config.contact.address }}</a
            >
          </p>

          <p *ngFor="let phone of config.contact.phone">
            <a href="tel:{{ phone.value }}"
              ><i class="icofont-phone"></i> {{ phone.label }}</a
            >
          </p>
          <p *ngFor="let email of config.contact.email">
            <a href="mailto:{{ email.value }}"
              ><i class="icofont-envelope"></i> {{ email.label }}</a
            >
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="contact-box">
          <h3>Opening Hours</h3>
          <p class="opening-hours">
            Sun - Thur
            <span
              >{{ config.openingHours.week.startTime }} -
              {{ config.openingHours.week.endTime }}</span
            >
          </p>
          <p class="opening-hours">
            Fri - Sat
            <span
              >{{ config.openingHours.weekend.startTime }} -
              {{ config.openingHours.weekend.endTime }}</span
            >
          </p>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6">
                <div class="contact-box">
                    <h3>Newsletter</h3>
                    <form class="newsletter-form">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Your email.."
                            name="EMAIL"
                            
                        />
                        <button type="submit" class="btn btn-primary">
                            <i class="icofont-paper-plane"></i>
                        </button>
                    </form>
                </div>
            </div> -->
      <div class="col-lg-12 col-md-12">
        <form id="contactForm" [formGroup]="contactForm" (ngSubmit)="submit()">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  placeholder="First name"
                  required
                />

                <div *ngIf="getControlErrors('firstName')?.['required']">
                  <p class="alert">First Name is required.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="lastName"
                  placeholder="Last name"
                  required
                />
              </div>
              <div *ngIf="getControlErrors('lastName')?.['required']">
                <p class="alert">Last Name is required.</p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <input
                  type="email"
                  formControlName="email"
                  class="form-control"
                  placeholder="Your email"
                  email
                  required
                />
                <div *ngIf="getControlErrors('email')?.['required']">
                  <p class="alert">Email is required.</p>
                </div>
                <div *ngIf="getControlErrors('email')?.['email']">
                  <p class="alert">
                    Email must be a valid email address.
                    <br />
                    i.e user@example.com.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="form-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  formControlName="contactNumber"
                  placeholder="Your phone"
                  required
                />
                <div *ngIf="getControlErrors('contactNumber')?.['required']">
                  <p class="alert">Contact Number is required.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group mb-3">
                <textarea
                  formControlName="message"
                  cols="30"
                  rows="5"
                  class="form-control"
                  placeholder="Your message..."
                  required
                ></textarea>
                <div *ngIf="getControlErrors('message')?.['required']">
                  <p class="alert">Message is required.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button
                type="submit"
                [disabled]="!contactForm.valid || loading"
                class="btn btn-primary"
                [class.btn-success]="messageSent"
              >
                <span *ngIf="!loading && !messageSent">Send Message</span>
                <div
                  *ngIf="loading"
                  class="spinner-border text-light"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <div *ngIf="messageSent">
                  <i class="icofont-check-circled"></i>
                  <span class="message-sent">Sent</span>
                </div>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
