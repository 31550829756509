<div id="home" class="main-banner video-banner">
  <!-- FIXME: Disabling Video element until it is fully working -->
  <!-- <video
    autoplay="autoplay"
    muted
    loop="true"
    playsinline
    class="video-background"
  >
    <source src="assets/video/video.mp4" type="video/mp4" />
  </video> -->
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="main-banner-content">
          <h1>Eva's Pizzeria</h1>
          <h2>Largs Bay</h2>
          <p class="text">
            Eva's Pizzeria serves the best pizzas in South Australia with an
            inventive menu and attentive service to leave you satisfied. Come
            taste the difference today!
          </p>
          <div class="button-group">
            <app-order-now-button></app-order-now-button>
            <!-- <app-table-reservation-button></app-table-reservation-button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="down_arrow">
    <a href="#about" class="scroll_down" aria-label="Scroll Down"></a>
  </div>
</div>
