<header class="top-area">
  <!-- Top Header -->
  <div class="top-bar template-color-bg">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-9">
          <div class="call-to-action">
            <p *ngFor="let email of config.contact.email">
              <a href="mailto:{{ email.label }}"
                ><i class="icofont-envelope"></i>{{ email.value }}</a
              >
            </p>
            <p *ngFor="let phone of config.contact.phone">
              <a href="tel:{{ phone.label }}"
                ><i class="icofont-phone"></i>{{ phone.value }}</a
              >
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-3">
          <ul class="top-social">
            <li>
              <a href="https://www.facebook.com/EvasPizzeria" target="_blank"
                ><i class="icofont-facebook"></i
              ></a>
            </li>
            <!-- <li>
              <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
            </li> -->
          </ul>
          <div class="opening-hours">
            <p>Opening hours:</p>
            <p>
              <i class="icofont-wall-clock"></i> Sun - Thur :
              {{ config.openingHours.week.startTime }} -
              {{ config.openingHours.week.endTime }}
            </p>
            <p>
              <i class="icofont-wall-clock"></i> Fri - Sat :
              {{ config.openingHours.weekend.startTime }} -
              {{ config.openingHours.weekend.endTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Default Navbar -->
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light transparent-navbar"
    stickyClass="sticky-box-shadow"
    ngStickyNav
  >
    <div class="container" [class.active]="classApplied">
      <a class="navbar-brand" href="/">
        <img class="logo" src="assets/img/logo.png" alt="white-logo" />
      </a>
      <a class="navbar-brand black-logo" href="/">
        <img class="logo" src="assets/img/logo.png" alt="black-logo" />
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleClass()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="/#home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#offer">Offer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#menu">Menu</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#contact">Contact</a>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto for-responsive custom-mobile">
          <li class="nav-item">
            <a class="nav-link" href="/#home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#offer">Offer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#menu">Menu</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#contact">Contact</a>
          </li>
          <li class="nav-item">
            <app-order-now-button></app-order-now-button>
          </li>
          <!-- <li class="nav-item">
            <app-table-reservation-button
              [isPrimaryColor]="true"
            ></app-table-reservation-button>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
