import { Component } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IOfferItem } from '../../../interfaces/offer-item.interface';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent {
  offers: IOfferItem[] = [
    {
      title: 'Nutella Dessert Pizza',
      description: 'Icing sugar, nutella, Strawberry, banana',
      offer_discount: '$12.00',
      image: 'assets/img/offers/offer-img1.jpg',
    },
    {
      title: 'Rustico',
      description:
        'Ham, capsicum, mushroom, olives, salami, mozzarella cheese with a rosa sauce.',
      offer_discount: '$20.00',
      image: 'assets/img/offers/offer-img2.jpg',
    },
    {
      title: 'American Pizza',
      description: 'Tomato base, cheese, salami.',
      offer_discount: '$14.50',
      image: 'assets/img/offers/offer-img3.jpg',
    },
  ];

  offerSlides: OwlOptions = {
    margin: 25,
    nav: false,
    loop: false,
    dots: true,
    autoplay: true,
    mouseDrag: true,
    autoplayHoverPause: true,
    navText: [
      "<i class='icofont-double-left'></i>",
      "<i class='icofont-double-right'></i>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
}
